<template>
  <PageContent class="licences">
    <template #header-right>
      <ButtonClassic
        :label="$t('filtre.filtres')"
        color="primary"
        :variant="showFilterSidebar ? 'solid' : 'ghost'"
        :class="{ active: showFilterSidebar, }"
        icon="right"
        :disabled="isLoading"
        @click="showFilterSidebar = !showFilterSidebar"
      >
        <template #right-icon>
          <UilFilter v-if="!showFilterSidebar" />
          <UilTimes v-else />
        </template>
      </ButtonClassic>
    </template>
    <template #aside-content>
      <FilterSidebar
        v-if="showFilterSidebar && allFilters.length"
        :title="$t('recherche.affiner-votre-recherche')"
        :possible-filters="allFilters"
        :active-filters="activeFilters"
        @update="fetchLicences(false)"
      />
    </template>
    <template #content>
      <div v-if="isLoading">
        <InfiniteLoader />
      </div>
      <div
        v-else-if="emptyData"
        class="empty-page"
      >
        <h2 class="s4 light">
          {{ $t('general.aucune-licence-active') }}
        </h2>
        <img
          :src="require('@lde/core_lde_vue/dist/assets/media/illus/illus_cart.svg')"
          :alt="$t('general.alt-image-illustration')"
        />
      </div>
      <template v-else>
        <div id="licences_table">
          <LicencesItem
            v-for="licence in licences"
            :key="licence.id"
            :ligne="licence"
            @select-licence="handleSelectedLigne($event)"
          />
        </div>
        <div
          v-if="totalRows > pageSize"
          class="pagination-container"
        >
          <Pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="pageSize"
            @change="(page) => $router.push({ query: { ...$route.query, page, }, })"
          />
        </div>
        <ModalAskLicences
          v-if="selectedLicenceOffres?.length"
          :key="selectedLicenceOffres[0].id"
          :lignes="selectedLicenceOffres"
          :nb-licences-offertes="selectedLicenceNbOffert"
          @submit="fetchLicences()"
        />
      </template>
    </template>
  </PageContent>
</template>

<script>
import {
  PageContent,
  ButtonClassic,
  InfiniteLoader,
  Pagination,
} from "@lde/core_lde_vue";

import LicencesItem from "@/components/table/LicencesItem.vue";
import FilterSidebar from "@/components/search/filters/FilterSidebar.vue";
import ModalAskLicences from "@/components/modals/ModalAskLicences.vue";

import { UilFilter, UilTimes } from "@iconscout/vue-unicons";

import Api from "@/modules/axios";

/**
 * Affiche les licences enseignant supplémentaires.
 */
export default {
  name: "Licences",
  components: {
    PageContent,
    ButtonClassic,
    Pagination,
    InfiniteLoader,
    LicencesItem,
    FilterSidebar,
    ModalAskLicences,
    UilFilter,
    UilTimes,
  },
  data() {
    return {
      isLoading: true,
      licences: [],
      currentPage: 1,
      pageSize: 12,
      totalRows: 0,
      showFilterSidebar: false,
      allFilters: [],
      activeFilters: [],
      selectedLicenceOffres: null,
      selectedLicenceNbOffert: null,
      currentOrdering: {
        label: "Titre",
        name: "titre",
        ordering: "libelle",
      },
      orderingOptions: [
        {
          label: this.$t("general.titre"),
          name: "titre",
          ordering: "libelle",
        },
        {
          label: this.$t("produit.ean"),
          name: "ean",
          ordering: "manuel_numerique__ean",
        },
        // TODO: Pour une v2 + rajouter la trad
        // {
        //   label: "EAN Offre",
        //   name: "ean_offre",
        //   ordering: "offre_gratuite__ean_lde",
        // },
        // {
        //   label: "Durée de validité",
        //   name: "duree_validite",
        //   ordering: "offre_gratuite__duree",
        // },
        // {
        //   label: "Nombre de licences élève",
        //   name: "nb_lic_eleve",
        //   ordering: "qte_livree",
        // },
        // {
        //   label: "Nombre de licences enseignant",
        //   name: "nb_lic_enseignant",
        //   ordering: "offre_gratuite__qte_livree",
        // },
      ],
    };
  },
  computed: {
    emptyData() {
      return !this.isLoading && !this.licences?.length;
    },
  },
  watch: {
    /**
     * Quand la recherche change dans la queryString
     */
    "$route.query": {
      handler(newValue, oldValue) {
        const { page } = newValue;
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.currentPage = parseInt(page, 10) || 1;
          this.fetchLicences();
        }
      },
    },
  },
  mounted() {
    const { page } = this.$route.query;
    if (page) {
      this.currentPage = parseInt(page, 10);
    }
    this.fetchLicences();
  },
  methods: {
    /**
     * Récupère les licences.
     */
    fetchLicences() {
      this.isLoading = true;
      this.showFilterSidebar = false;

      const filterQuery = Object.entries(this.$route.query).filter(([key]) => key !== "page").map(([key, val]) => {
        const slug = `${key}__id__in`;
        return [slug, val];
      });
      const params = {
        page_size: this.pageSize,
        page: this.currentPage,
        ...Object.fromEntries(filterQuery),
      };

      Api().get("/manuel_numerique/licences/", { params })
        .then((res) => {
          const { results, count, filtres } = res.data;
          this.licences = results;
          this.totalRows = count;
          this.allFilters = filtres;
        })
        .catch((e) => {
          this.$toast.error({
            title: this.$t("info.une-erreur-est-survenue"),
            content: e.response?.data.detail || e,
          });
        })
        .finally(() => { this.isLoading = false; });
    },
    /**
     * Trie les données du tableau selon la valeur choisie via le tableau.
     * @param {Object} event Donnée du tri.
     */
    changeOrderingTable(event) {
      const current = this.orderingOptions.find((option) => option.ordering === event.key);
      if (current && current.ordering !== this.currentOrdering.ordering) {
        this.currentOrdering = current;
      }
      this.$router.push({
        query: {
          ...this.$route.query,
          sort: `${event.sortAscended ? "" : "-"}${event.key}`,
          page: 1,
        },
      });
    },
    /**
     * Change l'ordre de tri via le select classic.
     * @param {Object} option Option sélectionnée.
     */
    changeOrderingSelect(option) {
      if (this.currentOrdering.ordering !== option.ordering) {
        this.currentOrdering = option;
        this.$router.push({
          query: {
            ...this.$route.query,
            sort: option.ordering,
            page: 1,
          },
        });
      }
      /**
       * On charge les nouveaux résultats
       */
      if (!this.isLoading) {
        this.fetchLicences();
      }
    },
    /**
     * Affiche la modale de demande de licence pour celle sélectionnée.
     * @param {Object} ligne Ligne sélectionnée.
     */
    handleSelectedLigne(ligne) {
      this.selectedLicenceOffres = ligne.offres;
      this.selectedLicenceNbOffert = ligne.quantite_offert;
      this.$nextTick(() => {
        // nextTick car v-if sur la modale (donc elle n'existe pas encore sinon)
        this.$modal.show("modal_ask_licences");
      });
    },
  },
};
</script>

<style lang="scss"></style>

<template>
  <div
    class="licences-item"
    :class="{ echue: !ligne.quantite_eleve && !ligne.quantite_enseignant && !ligne.quantite_offert, }"
  >
    <router-link
      class="main-link"
      :to="{ name: 'licences_actives_item', params: { id: ligne.id, }, }"
    />
    <div class="flex-vcenter content">
      <figure>
        <img
          :alt="$t('general.alt-image-produit')"
          :src="ligne.url_couverture
            || require('@lde/core_lde_vue/dist/assets/media/missing_img/no_img_numerique.svg')"
          @error="ligne.url_couverture = null"
        />
      </figure>
      <div class="right-content">
        <header class="flex-vcenter">
          <h1 class="text-regular">
            {{ ligne.libelle }}
          </h1>
          <UilExclamationTriangle
            v-if="toutesLicencesEchues(ligne)"
            v-tooltip="{
              content: toutesLicencesEchues(ligne)
                ? $t('produit.toutes-licences-expirees')
                : $t('produit.attention-certaines-licences-arriver-expiration'),
            }"
            class="status"
          />
        </header>
        <div class="inner-content flex-vcenter">
          <div class="info-content text-medium">
            <p v-if="ligne.ean">
              {{ $t("produit.ean") }} {{ ligne.ean }}
            </p>
          </div>
          <div v-if="ligne.demandes.length" />
          <div
            id="ask_licences_container"
            class="flex-vcenter"
          >
            <div
              v-if="ligne.quantite_eleve || ligne.quantite_enseignant || ligne.quantite_offert"
              v-tooltip="{ content: tooltip(ligne), }"
            >
              <ButtonClassic
                :label="$t('licence.demande-licences-enseignant-supplementaires')"
                variant="special"
                color="primary"
                icon="right"
                :disabled="isImpersonating || !canOrderLicence || ligne.demandes.length > 0"
                @click="$emit('select-licence', ligne)"
              >
                <template #right-icon>
                  <UilCommentDots />
                </template>
              </ButtonClassic>
            </div>
            <ButtonClassic
              balise-type="router-link"
              variant="special"
              :label="isEnseignant(ligne)
                ? $t('licence.commande-de-licences-enseignant')
                : $t('licence.commande-de-licences-eleve')"
              color="primary"
              icon="right"
              :router-link-obj="{ name: 'licences_actives_item', params: { id: ligne.id, }, }"
            >
              <template #right-icon>
                <UilArrowRight />
              </template>
            </ButtonClassic>
          </div>
        </div>
      </div>
    </div>
    <footer
      v-if="ligne.quantite_eleve || ligne.quantite_enseignant || ligne.quantite_offert"
      class="flex-vcenter text-medium"
    >
      <p v-if="ligne.quantite_eleve">
        <span class="footer-value">{{ ligne.quantite_eleve | spaceNumber }}</span>
        {{ $tc("produit.licence-eleve-active", ligne.quantite_eleve) }}
      </p>
      <p v-if="ligne.quantite_enseignant">
        <span class="footer-value">{{ ligne.quantite_enseignant | spaceNumber }}</span>
        {{ $tc("produit.licence-enseignant-active", ligne.quantite_enseignant) }}
      </p>
      <p v-if="ligne.quantite_offert">
        {{ $tc("produit.licence-enseignant-deja-offerte", ligne.quantite_offert, {qte: ligne.quantite_offert, }) }}
      </p>
    </footer>
  </div>
</template>

<script>
import { ButtonClassic } from "@lde/core_lde_vue";

import mixinLicences from "@/mixins/mixinLicences";

import { UilExclamationTriangle, UilCommentDots, UilArrowRight } from "@iconscout/vue-unicons";

/**
 * Ligne d'une licence dans un listing.
 */
export default {
  name: "LicencesItem",
  components: {
    ButtonClassic,
    UilExclamationTriangle,
    UilCommentDots,
    UilArrowRight,
  },
  mixins: [mixinLicences],
  props: {
    /**
     * Informations relatives à la licence.
     */
    ligne: {
      type: Object,
      required: true,
    },
  },
  emits: [
    /**
     * Déclenché lorsqu'on clique sur le bouton de demande de licences supplémentaires.
     */
    "select-licence",
    /**
     * Déclenché au clic sur le bouton de demande de licence dans la modale.
     * @param {Object} - Licence à commander.
     */
    "submit-ask-licence",
  ],
  computed: {
    nomDemande() {
      if (!this.ligne.demandes.length) {
        return "";
      }

      const firstDemandContact = this.ligne.demandes[0].utilisateur.contact;

      return `${firstDemandContact.prenom} ${firstDemandContact.nom}`;
    },
    dateDemande() {
      if (!this.ligne.demandes.length) {
        return "";
      }

      return this.$moment(this.ligne.demandes[0].date).format(this.$t("global.format-date"));
    },
  },
  methods: {
    /**
     * Vérifie si toutes les licences d'une références sont échues.
     * @param {ligne} ligne Licence à vérifier.
     * @returns {Boolean} Si true, les licences sont toutes échues.
     */
    toutesLicencesEchues(ligne) {
      return !ligne.quantite_eleve && !ligne.quantite_enseignant && !ligne.quantite_offert;
    },
    /**
     * Vérifie si la licence est de type enseignant.
     * @param {ligne} ligne Licence à vérifier.
     * @returns {Boolean} Si true, la licence est de type enseignant.
     */
    isEnseignant(ligne) {
      return !ligne.publics.some((p) => p.includes("lèv"));
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/table/licences_item.scss";
</style>

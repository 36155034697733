var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"licences-item",class:{ echue: !_vm.ligne.quantite_eleve && !_vm.ligne.quantite_enseignant && !_vm.ligne.quantite_offert, }},[_c('router-link',{staticClass:"main-link",attrs:{"to":{ name: 'licences_actives_item', params: { id: _vm.ligne.id, }, }}}),_c('div',{staticClass:"flex-vcenter content"},[_c('figure',[_c('img',{attrs:{"alt":_vm.$t('general.alt-image-produit'),"src":_vm.ligne.url_couverture
          || require('@lde/core_lde_vue/dist/assets/media/missing_img/no_img_numerique.svg')},on:{"error":function($event){_vm.ligne.url_couverture = null}}})]),_c('div',{staticClass:"right-content"},[_c('header',{staticClass:"flex-vcenter"},[_c('h1',{staticClass:"text-regular"},[_vm._v(" "+_vm._s(_vm.ligne.libelle)+" ")]),(_vm.toutesLicencesEchues(_vm.ligne))?_c('UilExclamationTriangle',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.toutesLicencesEchues(_vm.ligne)
              ? _vm.$t('produit.toutes-licences-expirees')
              : _vm.$t('produit.attention-certaines-licences-arriver-expiration'),
          }),expression:"{\n            content: toutesLicencesEchues(ligne)\n              ? $t('produit.toutes-licences-expirees')\n              : $t('produit.attention-certaines-licences-arriver-expiration'),\n          }"}],staticClass:"status"}):_vm._e()],1),_c('div',{staticClass:"inner-content flex-vcenter"},[_c('div',{staticClass:"info-content text-medium"},[(_vm.ligne.ean)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("produit.ean"))+" "+_vm._s(_vm.ligne.ean)+" ")]):_vm._e()]),(_vm.ligne.demandes.length)?_c('div'):_vm._e(),_c('div',{staticClass:"flex-vcenter",attrs:{"id":"ask_licences_container"}},[(_vm.ligne.quantite_eleve || _vm.ligne.quantite_enseignant || _vm.ligne.quantite_offert)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.tooltip(_vm.ligne), }),expression:"{ content: tooltip(ligne), }"}]},[_c('ButtonClassic',{attrs:{"label":_vm.$t('licence.demande-licences-enseignant-supplementaires'),"variant":"special","color":"primary","icon":"right","disabled":_vm.isImpersonating || !_vm.canOrderLicence || _vm.ligne.demandes.length > 0},on:{"click":function($event){return _vm.$emit('select-licence', _vm.ligne)}},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('UilCommentDots')]},proxy:true}],null,false,1288914817)})],1):_vm._e(),_c('ButtonClassic',{attrs:{"balise-type":"router-link","variant":"special","label":_vm.isEnseignant(_vm.ligne)
              ? _vm.$t('licence.commande-de-licences-enseignant')
              : _vm.$t('licence.commande-de-licences-eleve'),"color":"primary","icon":"right","router-link-obj":{ name: 'licences_actives_item', params: { id: _vm.ligne.id, }, }},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('UilArrowRight')]},proxy:true}])})],1)])])]),(_vm.ligne.quantite_eleve || _vm.ligne.quantite_enseignant || _vm.ligne.quantite_offert)?_c('footer',{staticClass:"flex-vcenter text-medium"},[(_vm.ligne.quantite_eleve)?_c('p',[_c('span',{staticClass:"footer-value"},[_vm._v(_vm._s(_vm._f("spaceNumber")(_vm.ligne.quantite_eleve)))]),_vm._v(" "+_vm._s(_vm.$tc("produit.licence-eleve-active", _vm.ligne.quantite_eleve))+" ")]):_vm._e(),(_vm.ligne.quantite_enseignant)?_c('p',[_c('span',{staticClass:"footer-value"},[_vm._v(_vm._s(_vm._f("spaceNumber")(_vm.ligne.quantite_enseignant)))]),_vm._v(" "+_vm._s(_vm.$tc("produit.licence-enseignant-active", _vm.ligne.quantite_enseignant))+" ")]):_vm._e(),(_vm.ligne.quantite_offert)?_c('p',[_vm._v(" "+_vm._s(_vm.$tc("produit.licence-enseignant-deja-offerte", _vm.ligne.quantite_offert, {qte: _vm.ligne.quantite_offert, }))+" ")]):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }